import axios from "axios";
import qs from "qs";
import router from "../router";
import store from "../store";

import { services } from "../../config";
import { notification } from "ant-design-vue";
import jwt from "jsonwebtoken";

// notification.config({
//   placement: "bottomRight",
//   bottom: "50px",
//   duration: 3,
// });

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  cancelToken: source.token,
});

instance.defaults.baseURL = `${services.oa_server}`;
axios.defaults.baseURL = `${services.oa_server}`;

// 请求拦截

instance.interceptors.request.use(
  // 有一部分请求不需要添加token
  async (config) => {
    // const now = new Date().getTime() + 5000;
    const tokenInfo = JSON.parse(localStorage.getItem("_tokenInfo"));
    if (!tokenInfo) {
      // router.replace("/login");
      source.cancel("");
      return config;
    }
    config.headers.Authorization = `Bearer ${tokenInfo.token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    }
  },
  async (error) => {
    // console.error(error.response);
    if (error.response)
      switch (error.response.status) {
        case 401:
          {
            if (error.config.url.includes("/api/v1/refreshToken")) {
              logout();
              return Promise.reject(error);
            } else if (!error.config._retry) {
              error.config._retry = true;
              await refresh_token();
              return instance(error.config);
            } else {
              notification.error(error.response.data);
            }
            break;
          }

          break;
        // case 400:
        //   router.push('/seting/rules');
        //   break;
      }

    return Promise.reject(error);
  }
);
export default {
  openget(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        params: data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  get(url, data) {
    return new Promise((resolve, reject) => {
      instance({
        method: "get",
        url,
        params: data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  openpost(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url,
        data: data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  post(url, data) {
    return new Promise((resolve, reject) => {
      instance({
        method: "post",
        url,
        data: data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete(url, data) {
    return new Promise((resolve, reject) => {
      instance({
        method: "delete",
        url,
        params: data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  put(url, data) {
    return new Promise((resolve, reject) => {
      instance({
        method: "put",
        url,
        data: data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  upload(url, data) {
    return new Promise((resolve, reject) => {
      instance
        .post(url, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

let refreshing = false;

async function refresh_token(time) {
  // console.log("谁重置了topken", time);

  let refreshToken = JSON.parse(window.localStorage.getItem("_tokenInfo"))
    .refreshToken;

  if (!refresh_token) {
    logout();
  }

  if (refreshing) {
    await sleep(2500);
    return;
  }
  refreshing = true;

  try {
    const res = await instance.post("/api/v1/refresh_token", {
      refreshToken,
    });

    if (res.data) {
      let str = jwt.decode(res.data.accessToken);
      // console.log(str);

      if (str) {
        let obj = str;
        obj.token = res.data.accessToken;
        obj.refreshToken = res.data.refreshToken;
        obj.exp = obj.exp * 1000;
        (obj.user = str.user),
          localStorage.setItem("_tokenInfo", JSON.stringify(obj));
        localStorage.setItem("_userInfo", JSON.stringify(obj.user));
        store.commit("SET_TOKENINFO", obj);
        store.commit("SET_LOGIN_STATUS", true);
        store.commit("SET_USERNAME", str.user.username);
        store.commit("SET_USERINFO", str.user);
        // store.commit("SET_SUPERUSER", str.super_user);
        store.commit("SET_ROLES", str.user.roles);
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    refreshing = false;
  }
}

async function sleep(t) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), t);
  });
}

function logout(params) {
  {
    notification.error({
      message: "Error",
      description: "Token invalid or expired,Please login again.",
    });
    store.commit("SET_LOGIN_STATUS", false);
    store.commit("SET_TOKENINFO", null);
    store.commit("SET_USERINFO", null);
    store.commit("SET_USERNAME", "");
    window.localStorage.removeItem("_tokenInfo");
    let modal = document.querySelector(".modal-backdrop");
    if (modal) document.removeChild(modal);
    router.replace("/login");
  }
}
