module.exports = {
  services: {
    oa_server:
      process.env.NODE_ENV === "production"
        ? "https://oa.springup.app"
        // : "https://oa.springup.app"
        // : "https://oa.springup.app",
    : "http://192.168.2.67:8070"
  },
};
