<template>
  <a-menu mode="inline" :inline-collapsed="collapsed" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys">
    <a-menu-item v-for="item in paymentSecond" :key="item.name">
      <router-link :to="item.link">
        <component :is="item.icon" />
        {{ item.name }}
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";

import {
  TeamOutlined,
  PayCircleOutlined,
  WalletOutlined,
  HomeOutlined,
  UserOutlined,
  AccountBookOutlined,
  CalendarOutlined,
} from "@ant-design/icons-vue";

const menuList = [
  // {
  //     name: "Home",
  //     link: "/",
  //     icon: "TeamOutlined"
  // },
  {
    name: "Overview",
    link: "/overview",
    icon: "HomeOutlined",
  },
  {
    name: "Leaves",
    link: "/leaves",
    icon: "TeamOutlined",
  },
  {
    name: "Salary",
    link: "/salary",
    icon: "WalletOutlined",
  },
  {
    name: "Account Settings",
    link: "/accountsettings",
    icon: "UserOutlined",
  },
  // {
  //     name: "Repositories",
  //     link: "/Repositories",
  //     icon: "TeamOutlined"
  // }
];

const paymentSecond = [
  {
    name: "Leave Approval",
    link: "/admin/leaveapproval",
    icon: "UserOutlined",
  },
  {
    name: "Annual Leaves",
    link: "/admin/annualleaves",
    icon: "UserOutlined",
  },
  {
    name: "Salaries",
    link: "/admin/salaries",
    icon: "AccountBookOutlined",
  },
  {
    name: "Users",
    link: "/admin/users",
    icon: "TeamOutlined",
  },
  {
    name: "Workdays",
    link: "/admin/workdays",
    icon: "CalendarOutlined",
  },

  // {
  //   name: "Leaves Types",
  //   link: "/admin/leavestype",
  //   icon: "UserOutlined",
  // },
  // {
  //   name: "Permission Rules",
  //   link: "/admin/permissionrules",
  //   icon: "UserOutlined",
  // },
];

export default {
  setup() {
    const router = useRouter();

    const v = reactive({
      collapsed: false,
      selectedKeys: [0],
      openKeys: [],
      preOpenKeys: ["sub1"],
    });
    watch(
      () => v.openKeys,
      (val, oldVal) => {
        v.preOpenKeys = oldVal;
      }
    );

    // 根据当前页面路径选中对应Menu

    Object.keys(paymentSecond).map((key) => {
      if (paymentSecond[key].link == router.currentRoute.value.path) {
        v.selectedKeys = [paymentSecond[key].name];
        return;
      }
      if (
        router.currentRoute.value.path.indexOf(paymentSecond[key].link) >= 0
      ) {
        v.selectedKeys = [paymentSecond[key].name];
        return;
      }
    });

    const toggleCollapsed = () => {
      v.collapsed = !v.collapsed;
      v.openKeys = v.collapsed ? [] : v.preOpenKeys;
    };

    // 页面切换
    const navigate = (e) => {
      router.replace(e);
    };
    const titleClick = (e, key) => {
      //  v.selectedKeys = [+key];
      // router.replace(`/${key}`);
    };

    return {
      ...toRefs(v),
      toggleCollapsed,
      menuList,
      paymentSecond,
      navigate,
      titleClick,
      WalletOutlined,
    };
  },

  components: {
    PayCircleOutlined,
    TeamOutlined,
    WalletOutlined,
    HomeOutlined,
    UserOutlined,
    AccountBookOutlined,
    CalendarOutlined,
  },
};
</script>
