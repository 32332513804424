import { formatSeconds, formatSmall, getcurBalance, accMul } from "./utils";
// import moment from "moment";
import * as dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
var isLeapYear = require("dayjs/plugin/isLeapYear");
var duration = require("dayjs/plugin/duration");

dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(isLeapYear);
dayjs.extend(duration);

export default {
  //时间格式化
  formatTime(dateStr, format) {
    if (format) return dayjs(dateStr).format(format);
    return dayjs(dateStr).format("YYYY/MM/DD HH:mm:ss");
  },
  // 相对时间
  DateFormNowNt(dateStr) {
    return dayjs(dateStr).fromNow();
  },
  // 计算星期几
  getDayofWeek(dateStr) {
    return dayjs(dateStr).day();
  },
  // 计算几号
  getDayofMonth(dateStr) {
    return dayjs(dateStr).date();
  },
  // 计算 几月
  getMonthofYear(dateStr) {
    return dayjs(dateStr).month();
  },
  // 获取是否闰年
  isLeapYear(dateStr) {
    return dayjs(dateStr).isLeapYear();
  },
  //
  isBefore(dateStr) {
    return dayjs().isBefore(dayjs(dateStr), "day");
  },
  // 是否已经过了
  isAfter(dateStr) {
    return dayjs().isAfter(dayjs(dateStr), "day");
  },
  isSameOrBefore(dateStr) {
    return dayjs().isSameOrBefore(dayjs(dateStr), "day");
  },
  formatTimeByDay(days = 0) {
    if (isNaN(days)) {
      days = 0;
    }
    let D = dayjs.duration(days, "days");
    return `${D.years()}年${D.months()}月${D.days()}天`;
  },
  getMonth() {
    const str = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return str[dayjs().get("month")];
  },

  // 将秒数格式为 xx:xx:xx的格式
  formatSeconds(val) {
    return formatSeconds(val);
  },

  // 防止数值过小导致被js改成科学计数法
  formatSmall(num) {
    return formatSmall(num);
  },
  //加密货币的最小单位换算
  getcurBalance(b, type, list) {
    return getcurBalance(b, type, list);
  },
  //js 默认的乘法有bug
  accMul(arg1, arg2) {
    return accMul(arg1, arg2);
  },
};
