import Layout from "@/views/layout/layout.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Overview",
        meta: { title: "overview" },
        component: () => import("@/views/overview/overview.vue"),
      },
      {
        path: "/leaves",
        name: "Leaves",
        meta: { title: "Leaves" },
        component: () => import("@/views/leaves/Leaves.vue"),
      },
      {
        path: "/salary",
        name: "Salary",
        meta: { title: "Salary" },
        component: () => import("@/views/salary/Salary.vue"),
      },

      // {
      //   path: "/overview",
      //   name: "Overview",
      //   meta: { title: "overview" },
      //   component: () => import("@/views/overview/overview.vue"),
      // },
      {
        path: "/account",
        name: "Account",
        component: () => import("@/views/AccountSettings.vue"),
        meta: {
          title: "Account",
        },
      },
      {
        path: "/admin",
        name: "Admin",
        meta: { serve: "oa_server", title: "Admin" },
        component: () => import("@/views/admin/Admin.vue"),
        redirect: "/admin/users",
        children: [
          {
            path: "leaveapproval",
            name: "Leave Approval",
            meta: { title: "Leave Approvasl" },
            component: () => import("@/views/admin/Leaveapproval.vue"),
          },
          {
            path: "annualleaves",
            name: "Annual Leaves",
            meta: { title: "Annual Leaves" },
            component: () => import("@/views/admin/AnnualLeaves.vue"),
          },
          {
            path: "users",
            name: "Users",
            meta: { title: "User" },
            component: () => import("@/views/admin/Users.vue"),
          },
          {
            path: "salaries",
            name: "Salaries",
            meta: { title: "Salary" },
            component: () => import("@/views/admin/Salaries.vue"),
          },
          // {
          //   path: "permissionrules",
          //   name: "Permission Rules",
          //   meta: { title: "Permission Rules" },
          //   component: () => import("@/views/admin/PermissionRules.vue"),
          // },
          {
            path: "Workdays",
            name: "Workdays",
            meta: { title: "Workdays" },
            component: () => import("@/views/admin/Workdays.vue"),
            // children: [],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    Meta: { title: "NotFound" },
  },
];
export default routes;
