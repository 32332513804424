import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import { notification } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import './version';

// 样式导入
import "ant-design-vue/dist/antd.css";
import "./assets/style/style.scss";
import "@/assets/style/space.css";

import axios from "@/utils/axios";
import filters from "@/utils/filters";
import message from "@/utils/message";

notification.config({
  placement: "topRight",
  bottom: "50px",
  duration: 3,
});

const app = createApp(App);

app.provide('$', { $axios: axios, $msg: message, $filters: filters });

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$notify = notification;
app.config.globalProperties.$msg = message;
app.config.globalProperties.$modal = Modal;
// 注册全局过滤方法
app.config.globalProperties.$filters = filters;

app
  .use(Antd)
  .use(store)
  .use(router)
  .mount("#app");
