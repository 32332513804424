<template>
  <a-menu
    mode="inline"
    :inline-collapsed="collapsed"
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
  >
    <a-menu-item
      v-for="(item, index) of menuList"
      :key="index"
      @click="navigate(item.link)"
    >
      <component :is="item.icon" />
      <span>{{ item.name }}</span>
    </a-menu-item>
  </a-menu>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";

import {
  TeamOutlined,
  PayCircleOutlined,
  WalletOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";
const menuList = [
  // {
  //     name: "Home",
  //     link: "/",
  //     icon: "TeamOutlined"
  // },
  {
    name: "Overview",
    link: "/",
    icon: "HomeOutlined",
  },
  {
    name: "Leaves",
    link: "/leaves",
    icon: "TeamOutlined",
  },
  {
    name: "Salary",
    link: "/salary",
    icon: "WalletOutlined",
  },
  {
    name: "Account",
    link: "/account",
    icon: "UserOutlined",
  },
  // {
  //     name: "Repositories",
  //     link: "/Repositories",
  //     icon: "TeamOutlined"
  // }
];
const paymentSecond = [
  {
    name: "Leave Approval",
    link: "/admin/leaveapproval",
  },
  {
    name: "Users",
    link: "/admin/users",
  },
  {
    name: "Workdays",
    link: "/admin/workdays",
  },

  {
    name: "Leaves Type",
    link: "/admin/leavestype",
  },
  //   {
  //       name: "Settings",
  //       link: "/payment/settings"
  //   }
];

export default {
  setup() {
    const router = useRouter();

    const v = reactive({
      collapsed: false,
      selectedKeys: [0],
      openKeys: [],
      preOpenKeys: ["sub1"],
    });
    watch(
      () => v.openKeys,
      (val, oldVal) => {
        v.preOpenKeys = oldVal;
      }
    );

    // 根据当前页面路径选中对应Menu
    Object.keys(menuList).map((key) => {
      if (menuList[key].link == router.currentRoute.value.path) {
        v.selectedKeys = [+key];
        return;
      }
      if (key >= paymentSecond.length) return;

      if (paymentSecond[key].link == router.currentRoute.value.path) {
        v.selectedKeys = [paymentSecond[key].name];
        v.openKeys = ["payment"];
        return;
      }
      if (
        router.currentRoute.value.path.indexOf(paymentSecond[key].link) >= 0
      ) {
        v.selectedKeys = [paymentSecond[key].name];
        v.openKeys = ["payment"];
        return;
      }
    });

    const toggleCollapsed = () => {
      v.collapsed = !v.collapsed;
      v.openKeys = v.collapsed ? [] : v.preOpenKeys;
    };

    // 页面切换
    const navigate = (e) => {
      router.replace(e);
    };
    const titleClick = (e, key) => {
      //  v.selectedKeys = [+key];
      // router.replace(`/${key}`);
    };

    return {
      ...toRefs(v),
      toggleCollapsed,
      menuList,
      paymentSecond,
      navigate,
      titleClick,
      WalletOutlined,
    };
  },

  components: {
    PayCircleOutlined,
    TeamOutlined,
    WalletOutlined,
    HomeOutlined,
    UserOutlined,
  },
};
</script>
